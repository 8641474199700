import { Icon } from '@nike/eds';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>Version 0.1.0</p>
      <Icon name="NikeSwoosh" />
    </footer>
  );
};

export { Footer };
