import { Text } from '@nike/eds';

const NotFound = () => {
  return (
    <div>
      <Text font="title-2" as="h2">
        Page Not Found
      </Text>
    </div>
  );
};

export { NotFound };
