import { Text } from '@nike/eds';
import { ReactComponentProps } from '../../types/types';
import { SectionHeading } from '../SectionHeader/SectionHeading';
import styles from './ChannelTypes.module.css';

interface ChannelTypesProps extends ReactComponentProps {
  channelTypes: string[];
  selectedType: string;
  onChannelTypeSelected: (type: string) => void;
}

const ChannelTypes = (props: ChannelTypesProps) => {
  return (
    <section>
      <SectionHeading>Channel Types</SectionHeading>
      <ul>
        <li
          className={`${styles.item} ${
            props.selectedType === '' ? styles.selected : ''
          }`}
        >
          <Text
            font={'body-1'}
            as={'p'}
            onClick={() => props.onChannelTypeSelected('')}
            className={`${styles.name} `}
          >
            All
          </Text>
        </li>
        {props.channelTypes.map((type) => (
          <li onClick={() => props.onChannelTypeSelected(type)}>
            <Text font={'body-1'} as={'p'}>
              {type}
            </Text>
          </li>
        ))}
      </ul>
    </section>
  );
};

export { ChannelTypes };
