import { Text } from '@nike/eds';
import { ReactElement } from 'react';
import { ReactComponentProps } from '../../types/types';
import styles from './SectionHeading.module.css';

interface SectionHeadingProps extends ReactComponentProps {
  status?: ReactElement;
}

const SectionHeading = (props: SectionHeadingProps) => {
  return (
    <div className={styles.container}>
      <Text font={'title-2'} as={'h2'} className={styles.heading}>
        {props.children}
      </Text>
      <div className={styles.status}>{props.status}</div>
    </div>
  );
};

export { SectionHeading };
