type Class = { new (...args: any[]): any };

const isInstanceOf = <T extends Class>(el: unknown, ctor: T): el is T => {
  return Boolean(el) && el instanceof ctor;
};

const isHTMLDivElement = (el: unknown): el is HTMLDivElement => {
  return isInstanceOf(el, HTMLDivElement);
};

const isHTMLParagraphElement = (el: unknown): el is HTMLParagraphElement => {
  return isInstanceOf(el, HTMLParagraphElement);
};

const isHTMLLiElement = (el: unknown): el is HTMLLIElement => {
  return isInstanceOf(el, HTMLLIElement);
};

export {
  isHTMLDivElement,
  isHTMLParagraphElement as isHTMLHeadingElement,
  isHTMLLiElement,
};
