import { FormEvent, useState } from 'react';
import { Button, Modal, Text, TextField } from '@nike/eds';
import {
  Channel,
  ChannelsStatus,
  ReactComponentProps,
} from '../../types/types';
import { SectionHeading } from '../SectionHeader/SectionHeading';
import { Status } from '../Status/Status';
import styles from './Channels.module.css';
import { ChannelItem } from '../ChannelItem/ChanneItem';

interface ChannelsProps extends ReactComponentProps {
  channels: Channel[];
  status: ChannelsStatus;
  selectedChannel: Channel | null;
  onChannelCreated: (name: string) => void;
  onChannelUpdated: (ch: Channel, name: string) => void;
  onChannelDeleted: (ch: Channel) => void;
  onChannelSelected: (ch: Channel | null) => void;
}

const Channels = (props: ChannelsProps) => {
  const [channelToDelete, setChannelToDelete] = useState<Channel | null>(null);
  const [newChannelName, setNewChannelName] = useState<string>('');
  const isDeleteModalOpen = Boolean(channelToDelete);

  const handleCreateChannel = (e: FormEvent) => {
    e.preventDefault();
    props.onChannelCreated(newChannelName);
    setNewChannelName('');
  };

  const handleDeleteChannel = (ch: Channel) => {
    setChannelToDelete(ch);
  };

  const handleChannelDeleteConfirmed = () => {
    if (!channelToDelete) return;
    props.onChannelDeleted(channelToDelete);
    setChannelToDelete(null);
  };

  const handleChannelDeleteCanceled = () => {
    setChannelToDelete(null);
  };

  const ModalFooterBtns = () => (
    <div className={styles['modal-button-wrapper']}>
      <Button size="small" onClick={handleChannelDeleteConfirmed}>
        Delete
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={handleChannelDeleteCanceled}
      >
        Cancel
      </Button>
    </div>
  );

  const getStatus = () => {
    if (props.status.isLoading) {
      return <Status>loading...</Status>;
    }

    if (props.status.channelToDelete) {
      return <Status>deleting...</Status>;
    }

    if (props.status.channelToUpdate) {
      return <Status>updating...</Status>;
    }

    return <></>;
  };

  return (
    <section className={styles['channels-section']}>
      <SectionHeading status={getStatus()}>Channels</SectionHeading>
      <Modal
        isOpen={isDeleteModalOpen}
        headerSlot={<h2>Delete Channel</h2>}
        footerSlot={<ModalFooterBtns />}
        onDismiss={handleChannelDeleteCanceled}
      >
        <p>
          Are you sure you want to delete{' '}
          <strong>{channelToDelete?.name}</strong>?
        </p>
      </Modal>
      <div className={styles['channels-wrapper']}>
        {props.channels.length ? (
          <ul className={styles['channels-list']}>
            {props.channels.map((ch) => (
              <ChannelItem
                key={ch.id}
                channel={ch}
                selected={ch.id === props.selectedChannel?.id}
                onDelete={handleDeleteChannel}
                onUpdate={props.onChannelUpdated}
                onSelect={props.onChannelSelected}
              />
            ))}
          </ul>
        ) : (
          <Text font="body-1" as="h2">
            No channels to display
          </Text>
        )}
        <form
          onSubmit={handleCreateChannel}
          className={styles['create-channel-form']}
        >
          <TextField
            id="new-channel-input"
            label="Create channel"
            type="text"
            placeholder="New channel name"
            value={newChannelName}
            onChange={(e) => setNewChannelName(e.target.value)}
          />
          <Button
            type="submit"
            size="large"
            disableRipple
            disabled={!Boolean(newChannelName)}
          >
            Create
          </Button>
        </form>
      </div>
    </section>
  );
};

export { Channels };
