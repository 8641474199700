import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Channel, ChannelsStatus } from '../types/types';

const useChannels = (baseUrl: string, token?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channelToUpdate, setChannelToUpdate] = useState<Channel | null>(null);
  const [channelToDelete, setChannelToDelete] = useState<Channel | null>(null);
  const [channels, setChannels] = useState<Channel[]>([]);
  const URL = `${baseUrl}/channels`;

  const config = useMemo(() => {
    return token
      ? {
          headers: { Authorization: `Bearer ${token}` },
        }
      : {};
  }, [token]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<Channel[]>(URL, config)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [URL, config]);

  const addChannel = (name: string) => {
    const body = { name };
    axios
      .post(URL, body, config)
      .then(({ data }) =>
        setChannels((channels) => [...channels, { id: data.id, name }]),
      )
      .catch(console.error);
  };

  const updateChannel = (channelToUpdate: Channel, name: string) => {
    setChannelToUpdate(channelToUpdate);
    const updatedChannel = { ...channelToUpdate, name };
    axios
      .put(URL, updatedChannel, config)
      .then(() =>
        setChannels((channels) =>
          channels.map((channel) => {
            return channel.id === updatedChannel.id ? updatedChannel : channel;
          }),
        ),
      )
      .catch(console.error)
      .finally(() => {
        setChannelToUpdate(null);
      });
  };

  const deleteChannel = (channelToDelete: Channel) => {
    setChannelToDelete(channelToDelete);
    axios
      .delete(`${URL}/${channelToDelete.id}`, config)
      .then(() => {
        setChannels((channels) =>
          channels.filter((ch) => ch.id !== channelToDelete.id),
        );
      })
      .catch(console.error)
      .finally(() => {
        setChannelToDelete(null);
      });
  };

  const status: ChannelsStatus = {
    isLoading,
    channelToUpdate,
    channelToDelete,
  };

  return [channels, addChannel, updateChannel, deleteChannel, status] as const;
};

export { useChannels };
