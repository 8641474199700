import { Text } from '@nike/eds';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { ReactComponentProps } from '../../types/types';

interface LoginProps extends ReactComponentProps {
  redirectUrl: string;
}

const Login = (props: LoginProps) => {
  const [isAuthenticated, signinWithRedirect] = useAuth();

  if (!isAuthenticated) {
    signinWithRedirect(props.redirectUrl);
    return <Text>Redirecting to authorization provider...</Text>;
  }

  return <Navigate to={props.redirectUrl} />;
};

export { Login };
