import { Button, Text } from '@nike/eds';
import { useNavigate } from 'react-router-dom';
import styles from './Logout.module.css';

const Logout = () => {
  const navigate = useNavigate();

  const login = () => {
    navigate('/login');
  };

  return (
    <div className={styles.logout}>
      <Text>You've been logged out!</Text>
      <Button onClick={login}>Log In</Button>
    </div>
  );
};

export { Logout };
