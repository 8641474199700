import { useState, useEffect, useRef } from 'react';

const DEFAULT_URL =
  'wss://299ztqhn2h.execute-api.us-east-1.amazonaws.com/test?clientId=abc123';

function useWebSocket<T>(url: string | null = DEFAULT_URL) {
  const ws = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState<T | null>(null);

  const sendMessage = (message: string) => {
    ws.current?.send(message);
  };

  useEffect(() => {
    if (!url) return;

    ws.current = new WebSocket(url);
    ws.current.onopen = () => {
      setIsConnected(true);
    };
    ws.current.onclose = () => {
      setIsConnected(false);
    };

    return () => {
      ws.current?.close();
    };
  }, [url]);

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      if (!isConnected) return;
      const message = JSON.parse(e.data);
      setLastMessage(message);
    };
  }, [isConnected]);

  return [lastMessage, isConnected, sendMessage] as const;
}

export { useWebSocket };
