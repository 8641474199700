import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { oktaAuthConfig } from '../config';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const OktaProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export { OktaProvider };
