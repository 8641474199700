import { Button, ButtonGroup, IconButton, Text, TextField } from '@nike/eds';
import { FormEvent, SyntheticEvent, useState } from 'react';
import { Channel, ReactComponentProps } from '../../types/types';
import {
  isHTMLDivElement,
  isHTMLHeadingElement,
  isHTMLLiElement,
} from '../../utils/utils';
import styles from './ChannelItem.module.css';

interface ChannelItemProps extends ReactComponentProps {
  channel: Channel;
  selected: boolean;
  onSelect: (ch: Channel | null) => void;
  onUpdate: (ch: Channel, name: string) => void;
  onDelete: (ch: Channel) => void;
}

const ChannelItem = (props: ChannelItemProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');

  const handleSelect = (e: SyntheticEvent) => {
    if (
      !isHTMLHeadingElement(e.target) &&
      !isHTMLLiElement(e.target) &&
      !isHTMLDivElement(e.target)
    )
      return;

    props.onSelect(props.channel);
  };

  const handleChannelUpdateApplied = (e: FormEvent) => {
    e.preventDefault();
    if (newName) {
      props.onUpdate(props.channel, newName);
      setIsUpdating(false);
      setNewName('');
    }
  };

  return (
    <li
      key={props.channel.id}
      className={`${styles.item} ${props.selected ? styles.selected : ''}`}
      onClick={(e) => handleSelect(e)}
    >
      <div className={styles['item-wrapper']}>
        <Text font={'body-1'} as={'p'}>
          {props.channel.name}
        </Text>
        <div className={styles['item-controls-wrapper']}>
          <ButtonGroup>
            <IconButton
              icon="Delete"
              label="Delete"
              size="small"
              variant="secondary"
              disableRipple
              onClick={() => props.onDelete(props.channel)}
            />
            <IconButton
              icon="Edit"
              label="Edit"
              size="small"
              variant="secondary"
              disableRipple
              onClick={() => setIsUpdating((updating) => !updating)}
            />
          </ButtonGroup>
        </div>
      </div>
      <form
        className={styles['edit-channel-form']}
        style={{
          display: isUpdating ? 'flex' : 'none',
        }}
        onSubmit={handleChannelUpdateApplied}
      >
        <div className={styles['input-wrapper']}>
          <TextField
            hideLabel
            label="Name"
            id="abc"
            type="text"
            placeholder="Enter new name..."
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </div>
        <Button
          type="submit"
          size="small"
          variant="secondary"
          disableRipple
          disabled={!Boolean(newName)}
        >
          Apply
        </Button>
      </form>
    </li>
  );
};

export { ChannelItem };
