import { Spinner, Text } from '@nike/eds';
import { ReactComponentProps } from '../../types/types';
import styles from './Status.module.css';

const Status = (props: ReactComponentProps) => {
  return (
    <div className={styles.status}>
      <Spinner />
      <Text font="body-1" as="p">
        {props.children}
      </Text>
    </div>
  );
};

export { Status };
