import '@nike/eds/dist/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { OktaProvider } from './hoc/OktaProvider';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <OktaProvider>
        <App />
      </OktaProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
