import { Channels } from '../../components/ChannelsList/Channels';
import { ChannelTypes } from '../../components/ChannelTypes/ChannelTypes';
import { MessageEmitter } from '../../components/MesageEmitter/MessageEmitter';
import {
  Channel,
  ChannelsStatus,
  ReactComponentProps,
} from '../../types/types';

interface AdminProps extends ReactComponentProps {
  selectedType: string;
  channelTypes: string[];
  channels: Channel[];
  status: ChannelsStatus;
  selectedChannel: Channel | null;
  setSelectedType: (type: string) => void;
  addChannel: (name: string) => void;
  updateChannel: (ch: Channel, name: string) => void;
  deleteChannel: (ch: Channel) => void;
  setSelectedChannel: (ch: Channel | null) => void;
}

const Admin = (props: AdminProps) => {
  return (
    <>
      <ChannelTypes
        selectedType={props.selectedType}
        channelTypes={props.channelTypes}
        onChannelTypeSelected={props.setSelectedType}
      />
      <Channels
        channels={props.channels}
        status={props.status}
        selectedChannel={props.selectedChannel}
        onChannelCreated={props.addChannel}
        onChannelUpdated={props.updateChannel}
        onChannelDeleted={props.deleteChannel}
        onChannelSelected={props.setSelectedChannel}
      />
      <MessageEmitter currentChannel={props.selectedChannel} />
    </>
  );
};

export { Admin };
