import { useEffect, useState } from 'react';
import { AppHeader, Button, Icon } from '@nike/eds';
import { useChannels } from './hooks/useChannels';
import { Channel } from './types/types';
import { Footer } from './components/Footer/Footer';
import { LoginCallback } from '@okta/okta-react';
import { Route, Routes } from 'react-router-dom';
import { Admin } from './pages/Admin/Admin';
import { useAuth } from './hooks/useAuth';
import { Login } from './pages/Login/Login';
import './App.css';
import { Logout } from './pages/Logout/Logout';
import { PrivateRoutes } from './router/PrivateRoutes';
import { NotFound } from './pages/NotFound/NotFound';

const BASE_URL = 'https://vf861ho77f.execute-api.us-east-1.amazonaws.com/test';

const App = () => {
  const [isAuthenticated, , signOut, , token] = useAuth();
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);
  const [channels, addChannel, updateChannel, deleteChannel, status] =
    useChannels(BASE_URL, token);
  const isSelectedChannelModified = [
    status.channelToDelete,
    status.channelToUpdate,
  ].includes(selectedChannel);

  useEffect(() => {
    if (isSelectedChannelModified) {
      setSelectedChannel(null);
    }
  }, [isSelectedChannelModified]);

  // TODO: derive channel types from list of channels
  const channelTypes: string[] = [];

  const adminProps = {
    selectedType,
    channelTypes,
    channels,
    status,
    selectedChannel,
    setSelectedType,
    addChannel,
    updateChannel,
    deleteChannel,
    setSelectedChannel,
  };

  return (
    <div className="app">
      <AppHeader
        className="header"
        appName="KISS Management"
        logoSlot={<Icon name="NikeSwoosh" size="l" />}
        avatarSlot={
          isAuthenticated ? <Button onClick={signOut}>Log Out</Button> : <></>
        }
      />
      <main className="main">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path={'/'} element={<Admin {...adminProps} />} />
            <Route path={'/admin'} element={<Admin {...adminProps} />} />
          </Route>
          <Route path="/login" element={<Login redirectUrl="/admin" />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer></Footer>
    </div>
  );
};

export { App };
