import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoutes = () => {
  const [isAuthenticated, , , isRedirectPath] = useAuth();
  const location = useLocation();
  const isLogoutPath = location.pathname === '/logout';
  const shouldRedirect = !isAuthenticated && !isLogoutPath && !isRedirectPath;

  return shouldRedirect ? <Navigate to="/login" /> : <Outlet />;
};

export { PrivateRoutes };
