import { useOktaAuth } from '@okta/okta-react';
import { matchPath, useLocation } from 'react-router-dom';
import { oktaAuthConfig } from '../config';

const useAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();
  const redirectPath = new URL(oktaAuthConfig.redirectUri).pathname;
  const isRedirectPath = Boolean(matchPath(redirectPath, location.pathname));
  const isAuthenticated = authState?.isAuthenticated;
  const token = oktaAuth.getAccessToken();

  const signinWithRedirect = (url: string) =>
    oktaAuth.signInWithRedirect({ originalUri: url });
  const signOut = () =>
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/logout`,
    });

  return [
    isAuthenticated,
    signinWithRedirect,
    signOut,
    isRedirectPath,
    token,
  ] as const;
};

export { useAuth };
