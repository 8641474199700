import { Button, Text, TextField } from '@nike/eds';
import { FormEvent, useState } from 'react';
import { useWebSocket } from '../../hooks/useWebSocket';
import { Channel, ReactComponentProps } from '../../types/types';
import { SectionHeading } from '../SectionHeader/SectionHeading';
import styles from './MessageEmitter.module.css';

interface MessageEmmiterProps extends ReactComponentProps {
  currentChannel: Channel | null;
}

const MessageEmitter = (props: MessageEmmiterProps) => {
  // TODO: replace WebSocket with HTTP call after
  const [, isConnected, sendMessage] = useWebSocket();
  const [message, setMessage] = useState<string>('');

  const channel = props.currentChannel?.name || 'No channel selected';
  const messageTemplate = {
    eventType: props.currentChannel,
    epc: '',
    timestamp: String(Date.now()),
    locationId: '',
    sensorId: '',
  };

  const handleMessageEmit = (e: FormEvent) => {
    e.preventDefault();
    if (isConnected && message) {
      sendMessage(
        JSON.stringify({
          ...messageTemplate,
          message,
        }),
      );

      setMessage('');
    }
  };

  return (
    <section>
      <SectionHeading>Message Emitter</SectionHeading>
      <div className={styles.channel}>
        <Text font="title-6" as="label">
          {channel}
        </Text>
      </div>
      <form className={styles['emitter-form']} onSubmit={handleMessageEmit}>
        <div className={styles['input-wrapper']}>
          <TextField
            id="emit-message-input"
            label="Emmit message"
            type="text"
            placeholder="Enter message"
            hideLabel
            value={message}
            disabled={!Boolean(props.currentChannel)}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <Button
          disabled={!Boolean(props.currentChannel) || !Boolean(message)}
          disableRipple
          type="submit"
        >
          Emit
        </Button>
      </form>
    </section>
  );
};

export { MessageEmitter };
